import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    totalResult: 0,
  },
  mutations: {
    SET_TOTAL_RESULT(state, totalResult: number) {
      state.totalResult = totalResult;
    }
  },
  actions: {
    setTotalResult(context, totalResult: number) {
      context.commit('SET_TOTAL_RESULT', totalResult);
    }
  }
})