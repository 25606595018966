







import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class Dice extends Vue {
  @Prop({default: 12}) readonly max: number;
  private value: number | null = null;

  public roll() {
    this.value = Math.floor(Math.random() * this.max) + 1;
    this.$emit('onDiceRoll', this.value);
  }
}
