












































import {Component, Vue} from "vue-property-decorator";

@Component({})
export default class Help extends Vue {

  private goBack() {
    this.$router.push("/")
  }

}
