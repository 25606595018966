















































import {Component, Vue} from "vue-property-decorator";
import {CalculationService} from "@/services/CalculationService"
import Cell from "./Cell.vue"
import NumberPicker from "@/components/NumberPicker.vue";

@Component({
  components: {
    NumberPicker,
    Cell,
  },
})
export default class Dice extends Vue {

  grid: number[][] = [
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0],
  ];
  rowPoints: number[] = [0, 0, 0, 0, 0];
  colPoints: number[] = [0, 0, 0, 0, 0];
  leftDiagonalPoints: number = 0;
  rightDiagonalPoints: number = 0;

  showNumberPicker: boolean = false;
  currentCellPosition: { x: number, y: number }

  get rows() {
    return this.grid.map(row => row);
  }

  get cols() {
    return this.grid.map((row, rowIndex) => this.grid.map(row => row[rowIndex]));
  }

  get leftDiagonal() {
    const result = [];
    for (let rowIndex = 0; rowIndex < this.grid.length; rowIndex++) {
      for (let colIndex = 0; colIndex < this.grid[rowIndex].length; colIndex++) {
        if (rowIndex === colIndex) {
          result.push(this.grid[rowIndex][colIndex]);
        }
      }
    }
    return result;
  }

  get rightDiagonal() {
    const result = [];
    for (let rowIndex = 0; rowIndex < this.grid.length; rowIndex++) {
      for (let colIndex = 0; colIndex < this.grid[rowIndex].length; colIndex++) {
        if (rowIndex + colIndex === 4) {
          result.push(this.grid[rowIndex][colIndex]);
        }
      }
    }
    return result;
  }

  private updateGridData(position: { x: number, y: number }) {
    this.currentCellPosition = position;
    this.showNumberPicker = true;
  }

  private calculatePoints() {
    this.rowPoints = this.rows.map(row => CalculationService.calculatePoints(row));
    this.colPoints = this.cols.map(row => CalculationService.calculatePoints(row));
    this.leftDiagonalPoints = CalculationService.calculatePoints(this.leftDiagonal) * 2;
    this.rightDiagonalPoints = CalculationService.calculatePoints(this.rightDiagonal) * 2;

    const totalPoints = this.rowPoints.filter(value => value >= 0).reduce((previousValue, currentValue) => previousValue + currentValue)
        + this.colPoints.filter(value => value >= 0).reduce((previousValue, currentValue) => previousValue + currentValue)
        + (this.leftDiagonalPoints >= 0 ? this.leftDiagonalPoints : 0)
        + (this.rightDiagonalPoints >= 0 ? this.rightDiagonalPoints : 0);

    this.$store.dispatch('setTotalResult', totalPoints);
  }

  private onNumberPicked(value: number) {
    this.$set(this.grid[this.currentCellPosition.y], this.currentCellPosition.x, value);
    this.calculatePoints();
    this.showNumberPicker = false;
  }

  private onCloseNumberPicker() {
    this.showNumberPicker = false;
  }

}
