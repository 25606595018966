
















import {Component, Vue} from 'vue-property-decorator';
import Grid from './Grid.vue'

@Component({
  components: {
    Grid,
  },
})
export default class Main extends Vue {
  private getTotalPoints() {
    return this.$store.state.totalResult;
  }

  // noinspection JSMethodCanBeStatic
  private openManual() {
    const open = window.open('http://middys.nsv.de/wp-content/uploads/2018/01/knister-dt.pdf', '_blank');
    if (open) {
      open.focus();
    }
  }
}
