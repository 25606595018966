






import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class Dice extends Vue {
  @Prop() private readonly value: number;
  @Prop() private readonly position: { x: number, y: number };
  @Prop() private readonly isResult: boolean;

  private get display() {
    if (this.value === 0) {
      return '';
    } else if (this.value < 0) {
      return 'X'
    }
    return this.value;
  }

  private get isDiagonal(): boolean {
    if (this.position) {
      return this.position.x === this.position.y || this.position.x + this.position.y === 4;
    }
    return false;
  }

  private handleCellClick() {
    this.$emit('onCellClick', this.position);
  }
}
