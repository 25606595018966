export class CalculationService {
  public static calculatePoints(values: number[]) {
    if (this.isNotComplete(values)) {
      return 0;
    } else if (this.isStreetWithoutSeven(values)) {
      return 12;
    } else if (this.isStreet(values)) {
      return 8;
    } else if (this.isFullHouse(values)) {
      return 8;
    } else if (this.isTwoTwins(values)) {
      return 3;
    } else if (this.isQuintuplet(values)) {
      return 10;
    } else if (this.isQuadruplet(values)) {
      return 6;
    } else if (this.isTriplet(values)) {
      return 3;
    } else if (this.isTwin(values)) {
      return 1;
    } else {
      return -1;
    }
  }

  static isNotComplete(values: number[]): boolean {
    return values.filter(value => value === 0).length > 0;
  }

  static isStreet(values: number[]): boolean {
    const sortedValues = [...values].sort((a, b) => a - b);
    for (let i = 0; i < values.length - 1; i++) {
      if (sortedValues[i] + 1 !== sortedValues[i + 1]) {
        return false;
      }
    }
    return true;
  }

  static isStreetWithoutSeven(values: number[]): boolean {
    if (values.find(value => value === 7)) {
      return false
    }
    return this.isStreet(values);
  }

  public static isFullHouse(values: number[]) {
    return this.isTriplet(values) && this.isTwin(values);
  }

  static isTwoTwins(values: number[]) {
    const counts: { [key: number]: number } = {};
    values.forEach(value => {
      counts[value] = counts[value] ? (counts[value] += 1) : 1;
    })
    return Object.values(counts).filter(count => count === 2).length === 2;
  }

  static isQuintuplet(values: number[]) {
    return this.count(values, 5);
  }

  static isQuadruplet(values: number[]) {
    return this.count(values, 4);
  }

  static isTriplet(values: number[]) {
    return this.count(values, 3);
  }

  static isTwin(values: number[]) {
    return this.count(values, 2);
  }

  private static count(values: number[], amount: number) {
    const counts: { [key: number]: number } = {};
    values.forEach(value => {
      counts[value] = value !== 0 && counts[value] ? (counts[value] += 1) : 1;
    })
    return Object.values(counts).filter(count => count === amount).length === 1
  }
}

