import Vue from "vue";
import VueRouter from "vue-router";
import Dice from "@/components/Dice.vue";
import Main from "@/components/Main.vue";
import Help from "@/components/Help.vue";

Vue.use(VueRouter);

const routes = [
  {path: '/', component: Main},
  {path: '/dice', component: Dice},
  {path: '/help', component: Help}
]

export default new VueRouter({
  routes
})