



























import {Component, Vue} from "vue-property-decorator";

@Component
export default class NumberPicker extends Vue {

  private clickedNumber(value: number) {
    this.$emit('numberPicked', value);
  }

  private close() {
    this.$emit('closeNumberPicker');
  }

}

